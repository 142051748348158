<script setup lang="ts">
import AppFormMultiSelect, {SelectSize} from "@/Components/Shared/forms/AppFormMultiSelect.vue";
import {apiPathUrl} from "@/Utils/Helpers";
import {ref, watch} from "vue";
import {debounce} from "lodash-es";

type Props = {
    game: string;
    modelValue: any;
    size?: SelectSize;
}

const { game, modelValue = undefined, size = "regular" } = defineProps<Props>();

const items = ref([]);
const multiselect = ref(null);

const search = debounce((search: string) => {
    if (!search) {
        return;
    }

    axios.get(apiPathUrl("/sets/search"), {
        params: {
            s: search,
            game: game,
        },
    })
    .then(res => {
        // get data array from resource
        items.value = res.data.data;
    })
}, 100);

const emit = defineEmits(["update:modelValue"]);
defineExpose({ multiselect });

watch(() => game, () => {
    items.value = [];
});
</script>

<template>
<AppFormMultiSelect
    name="set"
    ref="multiselect"
    track-by="id"
    input-label="name"
    placeholder="Alle sets"
    :options="items"
    mode="single"
    :selected="modelValue"
    :size="size"
    :initial-options="modelValue ? [modelValue] : null"
    @search="search"
    @change="($event) => $emit('update:modelValue', $event)"
/>
</template>
