<script setup lang="ts">
import AppAvatar, {
    AvatarSize,
} from "@/Components/Shared/avatar/AppAvatar.vue";
import { installAntlerComponent } from "@/Utils/component";
import { useAttrs } from "vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppUserPopover from "@/Components/Shared/user/AppUserPopover.vue";

type Props = {
    user: {
        username: string;
        path: string;
        avatar: string;
        first_name: string;
        last_name: string;
    };
    avatarSize?: AvatarSize;
    showName?: boolean;
    linkable?: boolean;
};
const { user, avatarSize = "small", showName = false, linkable = true } = defineProps<Props>();
const attrs = useAttrs();

const classes = {};
const { aClass } = installAntlerComponent("user-avatar", {}, classes, attrs);
</script>

<template>
    <AppUserPopover
        v-if="user && user.path && user.id"
        :user-id="user.id"
    >
        <component
            v-if="user"
            :is="linkable ? AppLink : 'div'"
            :href="linkable ? user.path : undefined"
            class="inline-flex items-center gap-1"
        >
            <AppAvatar
                v-if="user.avatar"
                :src="user.avatar"
                :size="avatarSize"
            />

            <div
                v-if="showName"
                class="text-sm"
            >
                <div>@{{ user.username }}</div>

                <small v-if="user.first_name || user.last_name">
                    {{ user.first_name }} {{ user.last_name }}
                </small>
            </div>
        </component>
    </AppUserPopover>
</template>
