<script setup lang="ts">
import AppListItem from "@/Components/Shared/list/AppListItem.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppList from "@/Components/Shared/list/AppList.vue";
import Logo from "@/Components/Icons/Logo.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppMenuBurger from "@/Components/Shared/menu/AppMenuBurger.vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import useEmitter from "@/Composables/useEmitter";
import {Game, GameEnum, getGameLink} from "@/Enums/GameEnum";
import {authUser} from "@/Utils/Helpers";
const { emitter } = useEmitter();

type Props = {
    openMenu?: boolean;
    game?: string;
};
const { openMenu = false } = defineProps<Props>();

const closeMenu = () => {
    emitter.emit("open-menu");
};
const menu = ref(null);
onClickOutside(menu, () => {
    if (openMenu) {
        closeMenu();
    }
});

const gamePaths = ['CARDS', 'SETS', 'POSTS', 'DECKS'];

const getVariant = (url, gameKey, path) => {
    const baseUrl = getGameLink(GameEnum[gameKey], path)
    return url.includes(baseUrl) ? 'mainActive' : 'main'
}

/**
 * Returns the label for the link based on the path.
 */
const getLinkLabel = (path) => {
    const labels = {
        CARDS: 'Kaarten',
        SETS: 'Sets',
        POSTS: 'Posts',
        DECKS: 'Decks'
    }
    return labels[path] || path
}
</script>

<template>
    <aside
        ref="menu"
        :class="[
            'fixed top-0 lg:left-0 z-40 flex flex-col h-screen',
            openMenu
                ? 'flex w-full max-w-[320px] overflow-auto'
                : 'hidden lg:flex lg:w-[220px]',
        ]"
    >
        <div
            :class="[
                'flex flex-col grow w-full border-r border-gray-300 bg-white transform transition-transform sm:min-h-1',
                openMenu
                    ? 'translate-x-0 w-full lg:max-w-none p-4'
                    : '-translate-x-[100%] lg:translate-x-0',
            ]"
        >
            <AppLink
                href="/"
                class="flex shrink-0 px-4 py-4 hover:bg-slate-100"
            >
                <Logo
                    :class="[
                        'text-primary',
                        openMenu
                            ? 'w-[100px] lg:w-[120px]'
                            : 'w-[80px] lg:w-[148px]',
                    ]"
                />
            </AppLink>

            <AppMenuBurger
                :model-value="openMenu"
                class="lg:hidden absolute top-4 right-4 z-10"
                @click="closeMenu"
            />

            <AppMenu variant="main">
                <AppMenuItem
                    v-if="authUser"
                    href="/feed"
                    :variant="
                        $page.component === 'Feed/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Jouw Feed
                </AppMenuItem>
                <AppMenuItem
                    href="/posts"
                    :variant="
                        $page.component === 'Posts/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Posts
                </AppMenuItem>
                <AppMenuItem
                    href="/decks"
                    :variant="
                        $page.component === 'Decks/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Decks
                </AppMenuItem>

                <template v-for="gameKey in Object.keys(GameEnum)" :key="gameKey">
                    <!-- Main game menu item -->
                    <AppMenuItem
                        :href="getGameLink(GameEnum[gameKey], 'BASE')"
                        :variant="getVariant($page.url, gameKey, 'BASE')"
                    >
                        {{ Game[GameEnum[gameKey]].title }}
                    </AppMenuItem>

                    <!-- Submenu items -->
                    <div class="grid grid-cols-2">
                        <AppMenuItem
                            v-for="path in gamePaths"
                            :key="path"
                            :href="getGameLink(GameEnum[gameKey], path)"
                            class="text-sm"
                            color="grayLight"
                            :variant="getVariant($page.url, gameKey, path)"
                        >
                            {{ getLinkLabel(path) }}
                        </AppMenuItem>
                    </div>
                </template>
            </AppMenu>

            <div class="p-3 flex flex-col shrink-0 border-t">
                <AppList class="grid grid-cols-2">
                    <AppListItem>
                        <AppLink
                            href="/algemene-voorwaarden"
                            size="xsmall"
                        >
                            Voorwaarden
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/cookies"
                            size="xsmall"
                        >
                            Cookies
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/over"
                            size="xsmall"
                        >
                            Over
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/privacy"
                            size="xsmall"
                        >
                            Privacy
                        </AppLink>
                    </AppListItem>
                </AppList>
            </div>
        </div>
    </aside>
    <div
        :class="[
            'fixed top-0 z-30 left-0 w-full h-full bg-black/50',
            openMenu ? 'lt-sm:flex' : 'hidden',
        ]"
    ></div>
</template>
